/*

This is a copy of WIP branch from https://github.com/RushPlay/player
Must be replaced with real module when it is ready for usage.

*/

import * as R from 'ramda'
import * as ReduxEffects from 'redux-effects'
import * as reselect from 'reselect'
import md5 from 'md5'
import { useSelector } from 'react-redux'

import * as Herz from '@rushplay/herz'
import * as api from '@rushplay/api-client'
import * as websockets from '@rushplay/websockets'

import * as Constants from './constants'
import * as Cookies from './cookies'

export const ADDRESS_UPDATED = '@rushplay/player/ADDRESS_UPDATED'
export const AFFILIATE_CLICK_ID_UPDATED =
  '@rushplay/player/AFFILIATE_CLICK_ID_UPDATED'
export const AFFILIATE_ID_UPDATED = '@rushplay/player/AFFILIATE_ID_UPDATED'
export const AFFILIATE_TAG_UPDATED = '@rushplay/player/AFFILIATE_TAG_UPDATED'
export const ALLOW_EMAIL_UPDATED = '@rushplay/player/ALLOW_EMAIL_UPDATED'
export const ALLOW_SMS_UPDATED = '@rushplay/player/ALLOW_SMS_UPDATED'
export const AVATAR_UPDATED = '@rushplay/player/AVATAR_UPDATED'
export const BALANCE_TYPE_CHANGE_AKNOWLEDGED =
  '@rushplay/player/BALANCE_TYPE_CHANGE_AKNOWLEDGED'
export const BALANCE_TYPE_CHANGE_PROMPTED =
  '@rushplay/player/BALANCE_TYPE_CHANGE_PROMPTED'
export const BALANCE_UPDATED = '@rushplay/player/BALANCE_UPDATED'
export const BALANCE_TOTALS_UPDATED = 'speedy/player/BALANCE_TOTALS_UPDATED'
export const COUNTRY_CODE_UPDATED = '@rushplay/player/COUNTRY_CODE_UPDATED'
export const DEPOSIT_COUNT_INCREMENTED =
  '@rushplay/player/DEPOSIT_COUNT_INCREMENTED'
export const DEPOSIT_COUNT_UPDATED = '@rushplay/player/DEPOSIT_COUNT_UPDATED'
export const DEPOSIT_INFORMATION_UPDATED =
  '@rushplay/player/DEPOSIT_INFORMATION_UPDATED'
export const ENFORCE_DEPOSIT_UPDATED =
  '@rushplay/player/ENFORCE_DEPOSIT_UPDATED'
export const INIT = '@rushplay/player/INIT'
export const LANGUAGE_UPDATED = '@rushplay/player/LANGUAGE_UPDATED'
export const PROGRESS_UPDATED = '@rushplay/player/PROGRESS_UPDATED'
export const PAYMENT_CONVERTED_AMOUNT_RESET =
  '@rushplay/player/PAYMENT_CONVERTED_AMOUNT_RESET'
export const PAYMENT_CONVERTED_AMOUNT_UPDATED =
  '@rushplay/player/PAYMENT_CONVERTED_AMOUNT_UPDATED'
export const RECEIPTS_UPDATED = 'speedy/player/RECEIPTS_UPDATED'
export const TOTAL_DEPOSITS_UPDATED = 'speedy/player/TOTAL_DEPOSITS_UPDATED'
export const SPORTS_SEEN_UPDATED = '@rushplay/player/SPORTS_SEEN_UPDATED'
export const WITHDRAW_INFORMATION_UPDATED =
  '@rushplay/player/WITHDRAW_INFORMATION_UPDATED'
export const MAHJONG_SEEN_UPDATED = 'MAHJONG_SEEN_UPDATED'
export const HORSE_RACING_SEEN_UPDATED = 'HORSE_RACING_SEEN_UPDATED'
export const ACCOUNT_DOCUMENTS_SEEN_UPDATED =
  '@rushplay/player/ACCOUNT_DOCUMENTS_SEEN_UPDATED'
export const PHONE_VERIFICATION_REQUIRED_UPDATED =
  '@rushplay/player/PHONE_VERIFICATION_REQUIRED_UPDATED'

const EMPTY_ARRAY = []

/**
 * Stores player info data
 * @memberof rushplay/player
 * @param {Object} response BE response
 * @returns {Object} Redux action
 */
export function playerInfoInit(response) {
  if (response instanceof Error) {
    return {
      type: INIT,
      error: true,
      payload: response,
    }
  }

  return {
    type: INIT,
    payload: {
      affiliateClickId: response.affiliateClickId || '',
      affiliateSubId: response.affiliateSubId || '',
      affiliateId: response.affiliateId || null,
      affiliateTag: response.affiliateTag || null,
      allowEmail: response.allowEmail || false,
      allowSMS: response.allowSMS || false,
      avatar: response.avatar || '',
      balanceCents: response.balanceCents || 0,
      betbackDropPoint: response.betbackDropPoint || 0,
      betbackProgress: response.betbackProgress || 0,
      betbackProgressPercentage: response.betbackProgressPercentage || 0,
      birthdate: response.birthdate || '',
      bonusBalanceCents: response.bonusBalanceCents || 0,
      city: response.city || '',
      cityKatakana: response.cityKatakana || '',
      claimableBalanceCents: response.claimableBalanceCents || 0,
      countryCallingCode: response.countryCallingCode || '',
      countryCode: response.countryCode || '',
      currency: response.currency || '',
      depositAttempts: response.depositAttempts || 0,
      depositNumber: response.depositNumber || 0,
      displayName: response.displayName || '',
      email: response.email || '',
      eurBalanceCents: response.eurBalanceCents || 0,
      firstName: response.firstName || '',
      firstNameKatakana: response.firstNameKatakana || '',
      gift: response.gift || null,
      inventoryNotifications: response.inventoryNotifications || 0,
      isPhoneVerificationRequired:
        response.requiresPhoneValidation === 'restricted',
      language: response.language || '',
      lastDepositCents: response.lastDepositCents || null,
      lastName: response.lastName || '',
      lastNameKatakana: response.lastNameKatakana || '',
      mainBetbackBalanceCents: response.mainBetbackBalanceCents || 0,
      mapsPlayerId: response.mapsPlayerId || '',
      mobile: response.mobile || '',
      moneyBalanceCents: response.moneyBalanceCents || 0,
      nextBetbackBalanceCents: response.nextBetbackBalanceCents || 0,
      personId: response.personId || null,
      rubies: response.rubies || 0,
      state: response.state || '',
      street: response.street || '',
      streetKatakana: response.streetKatakana || '',
      username: response.username || '',
      valueSegment: response.valueSegment || '',
      vip: response.vip || false,
      vipTier: response.vipTier || '',
      withdrawalsDisallowed: response.withdrawalsDisallowed || false,
      zip: response.zip || '',
    },
  }
}

/**
 * Fetches and stores player info response to state
 * @memberof rushplay/player
 * @returns {Object} Redux action
 */
export function fetchPlayerInfo(config = {}) {
  // TODO: replace 'fetchSession' with 'fetchPlayerInfo' when 'fetchPlayerInfo' contains player data
  // return api.fetchPlayerInfo({
  //   version: 2,
  //   success: res => playerInfoInit(res.value.result),
  //   failure: res =>
  //     playerInfoInit(
  //       new Error(`errors.${res.value.message || 'general.unknown'}`)
  //     ),
  // })
  return api.fetchSession({
    version: 1,
    token: config.token,
    success: sessionRes => [
      api.fetchPlayerInfo({
        version: 2,
        token: config.token,
        success: res => {
          const playerInfo = res.value.result
          const player = sessionRes.value.player

          return [
            playerInfoInit(
              R.mergeAll([
                R.path(['account'], player),
                R.path(['address'], player),
                player,
                playerInfo,
              ])
            ),
            config.success && config.success(),
          ]
        },
        failure: res => {
          // TODO: Avoid doing this request unconditionally. This error happens
          // if player is locked. We don’t want to spam them with error
          // messages in this case.
          if (res.value.message === 'error.accept-terms-and-conditions') {
            return
          }

          if (res.value.message === 'Phone number is not verified') {
            return
          }

          return playerInfoInit(
            new Error(`errors.${res.value.message || 'general.unknown'}`)
          )
        },
      }),
      fetchDepositInformation(),
    ],
    failure: res =>
      playerInfoInit(
        new Error(`errors.${res.value.message || 'general.unknown'}`)
      ),
  })
}

/**
 * Fetches and stores player’s deposit information
 * @returns {Object} Redux action
 */
export function fetchDepositInformation() {
  return api.fetchDepositInformation({
    success: res => updateDepositInformation(res.value),
    failure: res => {
      // TODO: Avoid doing this request unconditionally. This error happens
      // if player is locked. We don’t want to spam them with error
      // messages in this case.
      if (res.value.message === 'error.accept-terms-and-conditions') {
        return
      }

      if (res.value.message === 'Phone number is not verified') {
        return
      }

      const error =
        res instanceof Error
          ? new Error('errors.fetch-failed')
          : new Error(`errors.${res.message || 'general.unknown'}`)
      return updateDepositInformation(error)
    },
    version: 1,
  })
}

/**
 * Fetches and stores player’s withdrawal limits and wagering information
 * @returns {Object} Redux action
 */
export function fetchWithdrawInformation() {
  return api.fetchWithdrawInformation({
    success: res => updateWithdrawInformation(res.value),
    failure: res => {
      const error =
        res instanceof Error
          ? new Error('errors.fetch-failed')
          : new Error(res.value.message)
      return updateWithdrawInformation(error)
    },
    version: 1,
  })
}

export function updateDepositInformation(payload) {
  return {
    type: DEPOSIT_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

/**
 * A flag to enforce opening the user's wallet
 * @param {boolean} payload flag value
 * @return {Object} Redux action
 */
export function updateEnforceDeposit(payload) {
  return {
    type: ENFORCE_DEPOSIT_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateProgress(payload) {
  return {
    type: PROGRESS_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateReceipts(payload) {
  return {
    type: RECEIPTS_UPDATED,
    payload,
  }
}

export function updateBalanceTotals(payload) {
  return {
    type: BALANCE_TOTALS_UPDATED,
    payload,
  }
}

export function updateDepositTotals(payload) {
  return {
    type: TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}

export function updateWithdrawInformation(payload) {
  return {
    type: WITHDRAW_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

/**
 * Updates if player needs to verify their phone number
 * @memberof rushplay/player
 * @param {boolean} payload
 * @returns {Object} Redux action
 */
export function updatePhoneVerificationRequired(payload) {
  return {
    type: PHONE_VERIFICATION_REQUIRED_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateSportsSeen(payload) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.SPORTS_SEEN, payload, {
      maxAge: 180 * 24 * 60 * 60,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: SPORTS_SEEN_UPDATED,
      payload,
    })
  )
}

export function updateHorseRacingSeen(payload) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.HORSE_RACING_SEEN, payload, {
      maxAge: 180 * 24 * 60 * 60,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: HORSE_RACING_SEEN_UPDATED,
      payload,
    })
  )
}

export function updateAccountItemsSeen(payload) {
  return {
    type: ACCOUNT_DOCUMENTS_SEEN_UPDATED,
    payload,
  }
}

export function reducer(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return R.mergeRight(state, action.payload)
    }

    case ADDRESS_UPDATED: {
      const newAddress = R.pickBy(R.complement(R.isNil), {
        city: action.payload.city,
        street: action.payload.street,
        zip: action.payload.zip,
      })
      return R.mergeRight(state, newAddress)
    }

    case AFFILIATE_CLICK_ID_UPDATED: {
      return R.unless(
        R.pipe(getAffiliateClickId, Boolean),
        R.assoc('affiliateClickId', action.payload)
      )(state)
    }

    // TODO: Apply the same logic as for AFFILIATE_CLICK_ID_UPDATED?
    case AFFILIATE_ID_UPDATED: {
      return R.assoc('affiliateId', action.payload, state)
    }

    // TODO: Apply the same logic as for AFFILIATE_CLICK_ID_UPDATED?
    case AFFILIATE_TAG_UPDATED: {
      return R.assoc('affiliateTag', action.payload, state)
    }

    case ALLOW_EMAIL_UPDATED: {
      return R.assoc('allowEmail', action.payload, state)
    }

    case ALLOW_SMS_UPDATED: {
      return R.assoc('allowSMS', action.payload, state)
    }

    case AVATAR_UPDATED: {
      return R.assoc('avatar', action.payload, state)
    }

    case websockets.REGION_PROGRESS_CHANGED:
    case PROGRESS_UPDATED: {
      const newProgress = R.pick(
        [
          'claimableBalanceCents',
          'betbackDropPoint',
          'betbackProgress',
          'betbackProgressPercentage',
          'mainBetbackBalanceCents',
          'nextBetbackBalanceCents',
        ],
        action.payload
      )

      return R.mergeRight(state, newProgress)
    }

    // TODO: Remove when websockets dispatch `player/BALANCE_UPDATED`
    case websockets.BALANCE_CHANGED:
    case BALANCE_UPDATED: {
      if (R.gte(R.path(['lastFetched'], state), action.payload.timestamp)) {
        return state
      }

      const newBalance = R.pickBy(R.complement(R.isNil), {
        currency: action.payload.currency,
        rubies: action.payload.rubies,
        bonusBalanceCents: action.payload.bonusBalanceCents,
        moneyBalanceCents: action.payload.moneyBalanceCents,
        lastFetched: action.payload.timestamp,
      })

      return R.mergeRight(state, newBalance)
    }

    case BALANCE_TOTALS_UPDATED:
      return R.mergeRight(state, {
        balanceTotals: action.payload,
      })

    case BALANCE_TYPE_CHANGE_AKNOWLEDGED: {
      return R.dissoc('showBalanceTypeChangeMessage', state)
    }

    case BALANCE_TYPE_CHANGE_PROMPTED: {
      return R.assoc('showBalanceTypeChangeMessage', true, state)
    }

    case COUNTRY_CODE_UPDATED: {
      return R.assoc('countryCode', action.payload, state)
    }

    case DEPOSIT_COUNT_INCREMENTED: {
      return R.assoc('depositNumber', R.inc(state.depositNumber), state)
    }

    case DEPOSIT_COUNT_UPDATED: {
      return R.assoc('depositNumber', action.payload, state)
    }

    case DEPOSIT_INFORMATION_UPDATED:
      return R.assoc('depositInformation', action.payload, state)

    case ENFORCE_DEPOSIT_UPDATED: {
      return R.assoc('enforceDeposit', action.payload, state)
    }

    case LANGUAGE_UPDATED: {
      return R.assoc('language', action.payload, state)
    }

    case PAYMENT_CONVERTED_AMOUNT_RESET: {
      return R.assoc('convertedAmount', null, state)
    }

    case PAYMENT_CONVERTED_AMOUNT_UPDATED: {
      return R.assoc('convertedAmount', action.payload)(state)
    }

    case RECEIPTS_UPDATED: {
      return R.pipe(
        R.set(R.lensPath(['currentPage']), action.payload.meta.page),
        R.set(R.lensPath(['totalPages']), action.payload.meta.total_pages),
        R.set(R.lensPath(['receipts']), action.payload.receipts)
      )(state)
    }

    case TOTAL_DEPOSITS_UPDATED:
      return R.mergeRight(state, {
        totalDepositCents: action.payload,
      })

    case WITHDRAW_INFORMATION_UPDATED:
      return R.assoc('withdrawInformation', action.payload, state)

    case SPORTS_SEEN_UPDATED:
      return R.assoc('sportsSeen', action.payload, state)

    case HORSE_RACING_SEEN_UPDATED:
      return R.assoc('horseRacingSeen', action.payload, state)

    case websockets.SHUFTI_PRO_ADDITIONAL_DOCUMENT_STATUS_CHAGED:
    case websockets.SHUFTI_PRO_UPLOAD_FEEDBACK:
      return R.assoc('accountDocumentsSeen', false, state)

    case ACCOUNT_DOCUMENTS_SEEN_UPDATED:
      return R.assoc('accountDocumentsSeen', action.payload, state)

    case PHONE_VERIFICATION_REQUIRED_UPDATED:
      return R.assoc('isPhoneVerificationRequired', action.payload, state)

    case websockets.SESSION_EXPIRED:
      return {}

    default:
      return state
  }
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateClickId(state) {
  return R.path(['affiliateClickId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateSubId(state) {
  return R.path(['affiliateSubId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateId(state) {
  return R.path(['affiliateId'], state)
}

/**
 * Needed only to generate a btag for Google Analytics
 * @private
 * @param {Object} state Player state
 * @returns {?string}
 */
function getAffiliateTag(state) {
  return R.path(['affiliateTag'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getBirthdate(state) {
  return R.path(['birthdate'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getBtag(state) {
  const affiliateId = getAffiliateId(state)
  const affiliateTag = getAffiliateTag(state)

  if (!affiliateId || !affiliateTag) {
    return null
  }

  return `${affiliateId}_${affiliateTag}`
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCountryCode(state) {
  return R.path(['countryCode'], state)
}

/**
 * Get deposit count
 */
export function getDepositCount(state) {
  return R.pathOr(null, ['depositNumber'], state)
}

/**
 * Get initial deposit value
 */
export function getInitialDeposit(state) {
  return R.path(['depositInformation', 'initialDeposit'], state)
}

/**
 * Get last deposit amount in EUR
 * @returns {number}
 */
export function getLastDepositAmount(state) {
  return R.pathOr(null, ['lastDepositCents'], state)
}

export function getDepositLimits(state) {
  return R.pathOr([], ['depositInformation', 'depositLimits'], state)
}

export const getDepositLimitRemainder = reselect.createSelector(
  [getDepositLimits],
  depositLimits =>
    R.reduce(
      R.min,
      Number.POSITIVE_INFINITY,
      Herz.Utils.Arrays.pluck('leftToDepositCents', depositLimits)
    )
)

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getDisplayName(state) {
  return R.path(['displayName'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getEmail(state) {
  return R.path(['email'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getFirstName(state) {
  return R.path(['firstName'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getFirstNameKatakana(state) {
  return R.path(['firstNameKatakana'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getLanguage(state) {
  return R.path(['language'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getLastName(state) {
  return R.path(['lastName'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getLastNameKatakana(state) {
  return R.path(['lastNameKatakana'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getPersonId(state) {
  return R.pathOr('', ['personId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getSsn(state) {
  return R.slice(2, Number.POSITIVE_INFINITY, getPersonId(state))
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getUsername(state) {
  return R.path(['username'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getMapsPlayerId(state) {
  return state?.mapsPlayerId || ''
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getPlayerId(state) {
  const username = getUsername(state)
  return typeof username === 'string' ? md5(username) : null
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getValueSegment(state) {
  return R.path(['valueSegment'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getVipLevel(state) {
  return R.path(['vipTier'], state)
}

export const getWithdrawalInformation = reselect.createSelector(
  [R.pathOr({}, ['withdrawInformation'])],
  state => ({
    bonusBalanceCents: state.bonusBalanceCents,
    moneyBalanceCents: state.moneyBalanceCents,
    sportWagerBalanceCents: state.sportWagerBalanceCents,
    wagerBalanceCents: state.wagerBalanceCents,
  })
)

export const getWithdrawableBalanceCents = reselect.createSelector(
  [getWithdrawalInformation],
  info => info.moneyBalanceCents
)

/**
 * @typedef {Object} WithdrawLimitsResult
 * @property {string} interval wd limit interval day || week || month
 * @property {number} limitCents wd limit in cents
 * @property {number} leftToWithdrawCents remaining amount of cents to wd
 */

/**
 * @param {Object} state Player state
 * @returns {Array.<WithdrawLimitsResult>}
 */
export function getWithdrawLimits(state) {
  return R.pathOr([], ['withdrawInformation', 'withdrawLimits'], state)
}

/**
 * ADDRESS PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCity(state) {
  return R.path(['city'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCityKatakana(state) {
  return R.path(['cityKatakana'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getStreet(state) {
  return R.path(['street'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getStreetKatakana(state) {
  return R.path(['streetKatakana'], state)
}

/**
 * @param {Object} state Player state (polity)
 * @returns {string}
 */
export function getState(state) {
  return R.path(['state'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getZip(state) {
  return R.path(['zip'], state)
}

/**
 * BALANCE PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCurrency(state) {
  // TODO: Determine fallback currency in proper way.
  return R.pathOr('EUR', ['currency'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBalanceCents(state) {
  return getBonusBalanceCents(state) + getMoneyBalanceCents(state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBonusBalanceCents(state) {
  return R.path(['bonusBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMoneyBalanceCents(state) {
  return R.path(['moneyBalanceCents'], state)
}

/**
 * PHONE NUMBER PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getCountryCallingCode(state) {
  return R.path(['countryCallingCode'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMobile(state) {
  return R.path(['mobile'], state)
}

export function getPhoneNumber(state) {
  const countryCode = getCountryCallingCode(state)
  const phoneNumber = getMobile(state)
  return (
    countryCode &&
    phoneNumber &&
    `${countryCode}${R.replace(/^0+/, '', phoneNumber)}`
  )
}

export function useCurrency() {
  return useSelector(state => getCurrency(state.player))
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBetBackDropPoint(state) {
  return R.path(['betbackDropPoint'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBetBackProgress(state) {
  return R.path(['betbackProgress'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBetBackProgressPercentage(state) {
  return R.path(['betbackProgressPercentage'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMainBetBackBalanceCents(state) {
  return R.path(['mainBetbackBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getNextBetBackBalanceCents(state) {
  return R.path(['nextBetbackBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getClaimableBalanceCents(state) {
  return R.path(['claimableBalanceCents'], state)
}

export function getReceipts(state) {
  return R.pathOr(EMPTY_ARRAY, ['receipts'], state)
}

export function getTotalReceiptPages(state) {
  return state.totalPages
}

export function getCurrentReceiptPage(state) {
  return state.currentPage
}

export function getTotalDepositsCents(state) {
  return R.or(
    R.path(['totalDepositCents', 'result', 'total_deposit_cents'], state),
    R.path(['balanceTotals', 'depositsCents'], state)
  )
}

export function getTotalWithdrawsCents(state) {
  return R.path(['balanceTotals', 'withdrawsCents'], state)
}

export function getEnforceDeposit(state) {
  return R.path(['enforceDeposit'], state)
}

export function getEnforceProfileRedirect(state) {
  return R.path(['enforceProfileRedirect'], state)
}

export function isPhoneVerificationRequired(state) {
  return R.path(['isPhoneVerificationRequired'], state)
}

export function hasSeenSports(state) {
  return R.pathOr(false, ['sportsSeen'], state)
}

export function hasPlayerSeenHorseRacing(state) {
  return R.pathOr(false, ['horseRacingSeen'], state)
}

export function hasSeenAllAccountDocuments(state) {
  return R.pathOr(true, ['accountDocumentsSeen'], state)
}

export function getDepositAttempts(state) {
  return R.pathOr(0, ['depositAttempts'], state)
}
