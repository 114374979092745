import { createTheme } from './create-theme'

const STATIC_COLORS = [
  { name: 'static-black', value: 'hsl(0, 0%, 0%)' },
  { name: 'static-white', value: 'hsl(0, 0%, 100%)' },
]

const BASE_COLORS = [
  { name: 'base-50', value: 'hsl(284, 90%, 1%)' },
  { name: 'base-75', value: 'hsl(284, 70%, 5%)' },
  { name: 'base-100', value: 'hsl(284, 62%, 7%)' },
  { name: 'base-200', value: '#0E9FFF' },
  { name: 'base-300', value: '#8b8c8d' },
  { name: 'base-400', value: '#8b8c8d' },
  { name: 'base-500', value: '#929ba1)' },
  { name: 'base-600', value: 'hsl(284, 5%, 52%)' },
  { name: 'base-650', value: 'hsl(284, 5%, 52%)' },
  { name: 'base-700', value: '#bebebf' },
  { name: 'base-800', value: '#C2C2C2' },
  { name: 'base-900', value: '#f6f6f6' },
  { name: 'base-1000', value: 'hsl(284, 1%, 100%)' },

  { name: 'negative-50', value: 'hsl(6, 77%, 45%)' },
  { name: 'negative-75', value: 'hsl(6, 77%, 47%)' },
  { name: 'negative-100', value: 'hsl(6, 79%, 48%)' },
  { name: 'negative-200', value: 'hsl(6, 85%, 49%)' },
  { name: 'negative-300', value: 'hsl(6, 90%, 55%)' },
  { name: 'negative-400', value: 'hsl(6, 95%, 63%)' },
  { name: 'negative-500', value: 'hsl(6, 90%, 68%)' },
  { name: 'negative-600', value: 'hsl(6, 90%, 73%)' },
  { name: 'negative-700', value: 'hsl(6, 90%, 80%)' },
  { name: 'negative-800', value: 'hsl(6, 85%, 85%)' },
  { name: 'negative-900', value: 'hsl(6, 80%, 90%)' },
  { name: 'negative-1000', value: 'hsl(6, 75%, 95%)' },

  { name: 'positive-50', value: 'hsl(158, 100%, 8%)' },
  { name: 'positive-75', value: 'hsl(158, 100%, 10%)' },
  { name: 'positive-100', value: 'hsl(158, 100%, 15%)' },
  { name: 'positive-200', value: 'hsl(158, 100%, 20%)' },
  { name: 'positive-300', value: 'hsl(158, 100%, 24%)' },
  { name: 'positive-400', value: 'hsl(158, 99%, 26%)' },
  { name: 'positive-500', value: 'hsl(158, 98%, 30%)' },
  { name: 'positive-600', value: 'hsl(158, 95%, 35%)' },
  { name: 'positive-700', value: 'hsl(158, 60%, 45%)' },
  { name: 'positive-800', value: 'hsl(158, 50%, 60%)' },
  { name: 'positive-900', value: 'hsl(158, 45%, 70%)' },
  { name: 'positive-1000', value: 'hsl(158, 35%, 90%)' },

  { name: 'warning-50', value: 'hsl(41, 100%, 20%)' },
  { name: 'warning-75', value: 'hsl(41, 100%, 27%)' },
  { name: 'warning-100', value: 'hsl(41, 100%, 35%)' },
  { name: 'warning-200', value: 'hsl(41, 100%, 45%)' },
  { name: 'warning-300', value: 'hsl(41, 100%, 55%)' },
  { name: 'warning-400', value: 'hsl(41, 100%, 67%)' },
  { name: 'warning-500', value: 'hsl(41, 100%, 70%)' },
  { name: 'warning-600', value: 'hsl(41, 100%, 75%)' },
  { name: 'warning-700', value: 'hsl(41, 95%, 80%)' },
  { name: 'warning-800', value: 'hsl(41, 85%, 80%)' },
  { name: 'warning-900', value: 'hsl(41, 80%, 85%)' },
  { name: 'warning-1000', value: 'hsl(41, 70%, 93%)' },

  { name: 'info-50', value: 'hsl(183, 100%, 15%)' },
  { name: 'info-75', value: 'hsl(183, 90%, 20%)' },
  { name: 'info-100', value: 'hsl(183, 90%, 30%)' },
  { name: 'info-200', value: 'hsl(183, 85%, 35%)' },
  { name: 'info-300', value: 'hsl(183, 80%, 40%)' },
  { name: 'info-400', value: 'hsl(183, 72%, 43%)' },
  { name: 'info-500', value: 'hsl(183, 60%, 55%)' },
  { name: 'info-600', value: 'hsl(183, 55%, 60%)' },
  { name: 'info-700', value: 'hsl(183, 55%, 65%)' },
  { name: 'info-800', value: 'hsl(183, 50%, 70%)' },
  { name: 'info-900', value: 'hsl(183, 45%, 80%)' },
  { name: 'info-1000', value: 'hsl(183, 35%, 90%)' },
]

// These colors are very specific. Can't be found in current palettes
const COMPONENT_COLORS = [
  {
    name: 'game-info-missing-image-gradient-start',
    value: 'hsl(235, 76%, 56%)',
  },
  { name: 'loyalty-progress-bar-gradiant-end', value: 'hsl(305, 57%, 45%)' },
  { name: 'my-profile-background', value: 'hsl(282, 52%, 30%)' },
  { name: 'page-gradient-start', value: 'white' },
  { name: 'page-gradient-middle', value: 'white' },
  { name: 'loyalty-progress-bar-gradiant-start', value: 'hsl(42, 100%, 74%)' },
  { name: 'button-primary-background', value: 'hsla(166, 62%, 58%, 1)' },
  { name: 'button-secondary-background', value: '#CBE4DF' },
  { name: 'base-purple', value: '#130830' },
  { name: 'darken-gray', value: '#666975' },
  { name: 'page-nav-blue', value: '#4BBAEB' },
]

export default createTheme({
  breakpoints: [
    { name: 'sm', value: '800px' },
    { name: 'md', value: '1000px' },
    { name: 'lg', value: '1200px' },
  ],

  colors: [
    ...STATIC_COLORS,
    ...BASE_COLORS,
    ...COMPONENT_COLORS,

    // ALIASES
    { __type: 'alias', name: 'badge-background', value: 'warning-400' },
    { __type: 'alias', name: 'button-text-disabled', value: 'base-300' },
    { __type: 'alias', name: 'button-text', value: 'static-white' },
    { __type: 'alias', name: 'card-background', value: 'static-white' },
    { __type: 'alias', name: 'card-text', value: '#666975' },

    { __type: 'alias', name: 'provider-search', value: 'primary' },
    { __type: 'alias', name: 'provider-search-hover', value: 'secondary' },
    { __type: 'alias', name: 'provider-search-active', value: 'secondary' },
    {
      __type: 'alias',
      name: 'cookie-banner-background',
      value: 'static-white',
    },
    { __type: 'alias', name: 'checkbox-checked', value: 'positive-400' },
    {
      __type: 'alias',
      name: 'cookie-banner-background',
      value: 'static-white',
    },
    { __type: 'alias', name: 'drawer-text-color', value: 'static-black' },
    { __type: 'alias', name: 'danger', value: 'warning' },
    { __type: 'alias', name: 'dashboard-edit-button', value: 'base-75' },
    { __type: 'alias', name: 'disabled', value: 'base-800' },
    { __type: 'alias', name: 'g-bg', value: 'base-purple' },
    { __type: 'alias', name: 'g-text', value: 'lavender' },
    {
      __type: 'alias',
      name: 'failed-loading-casino-background',
      value: 'base-100',
    },
    {
      __type: 'alias',
      name: 'featured-label-background',
      value: 'base-300',
    },
    {
      __type: 'alias',
      name: 'featured-label-text',
      value: 'base-purple',
    },
    {
      __type: 'alias',
      name: 'footer-background',
      value: 'base-purple',
    },
    { __type: 'alias', name: 'game-tile-background', value: 'static-white' },
    {
      __type: 'alias',
      name: 'heading-text',
      value: 'base-200',
    },
    {
      __type: 'alias',
      name: 'input-background',
      value: 'base-purple',
    },
    {
      __type: 'alias',
      name: 'input-text',
      value: 'black',
    },
    {
      __type: 'alias',
      name: 'page-gradient-stop',
      value: 'base-300',
    },
    {
      __type: 'alias',
      name: 'loyalty-program-progressbar-track',
      value: 'base-300',
    },
    { __type: 'alias', name: 'menu-balance-background', value: 'base-200' },
    {
      __type: 'alias',
      name: 'menu-balance-visibility-toggler',
      value: 'base-700',
    },
    {
      __type: 'alias',
      name: 'menu-balance-visibility-toggler-hover',
      value: 'base-500',
    },
    { __type: 'alias', name: 'menu-loyalty-background', value: 'base-100' },
    { __type: 'alias', name: 'my-identity-dimmed', value: 'base-650' },
    { __type: 'alias', name: 'nav', value: 'blueberry' },
    { __type: 'alias', name: 'nav-active', value: 'base-700' },
    { __type: 'alias', name: 'nav-highlight', value: 'negative-400' },
    { __type: 'alias', name: 'nav-hover', value: 'base-800' },
    { __type: 'alias', name: 'nav-item', value: 'base-200' },
    { __type: 'alias', name: 'page-nav-link', value: '#434343' },
    { __type: 'alias', name: 'page-nav-link-active', value: 'red' },
    { __type: 'alias', name: 'picked-game', value: 'info-100' },
    {
      __type: 'alias',
      name: 'registration-progress-background',
      value: 'base-75',
    },
    {
      __type: 'alias',
      name: 'registration-progress-active-background',
      value: 'info-100',
    },
    { __type: 'alias', name: 'store-tile-bg', value: 'base-300' },
    { __type: 'alias', name: 'store-tile-control-wrapper', value: 'base-200' },
    { __type: 'alias', name: 'store-tile-control-button', value: 'info-400' },
    { __type: 'alias', name: 'store-tile-missing-img-bg', value: 'base-200' },
    { __type: 'alias', name: 'text-disabled', value: 'base-800' },
    {
      __type: 'alias',
      name: 'unseen-inventory-border',
      value: 'secondary-inventory-color',
    },
    {
      __type: 'alias',
      name: 'unseen-promotion-border',
      value: 'secondary-inventory-color',
    },
    { __type: 'alias', name: 'wallet-progress-background', value: 'base-75' },
    {
      __type: 'alias',
      name: 'game-info-statistic-background',
      value: 'base-800',
    },

    // VARIANTS
    //  - ALTERNATIVE
    {
      __type: 'alias',
      name: 'g-bg',
      value: 'base-purple',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'g-text',
      value: 'base-200',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'g-text-provider',
      value: 'static-white',
      variane: 'alternative',
    },
    {
      __type: 'alias',
      name: 'heading-text',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'input-background',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'input-text',
      value: 'black',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-small',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-large',
      value: 'base-100',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-text',
      value: 'base-75',
      variant: 'alternative',
    },
    { __type: 'alias', name: 'disabled-menu-item', value: 'base-800' },

    // LEGACY
    { name: 'secondary-inventory-color', value: '#42abdf' },
    { name: 'header-text', value: '#101B3C' },
    { name: 'inventory-background', value: '#eaeaea' },
    { name: 'cardBackground', value: '#dedede' },
    { name: 'card-background', value: '#1A0B41' },
    { name: 'my-identity-background', value: '#26125E' },
    { name: 'my-identity-warning', value: '#FF3D71' },
    { name: 'card-text', value: '#666975' },
    { name: 'page-nav-link-active', value: '#42aadf' },
    { name: 'checkbox-checked-text', value: 'white' },
    { name: 'game-navigation-item', value: '#434343' },
    { name: 'game-section-title', value: '#434343' },
    { name: 'game-page-title', value: '#434343' },
    { name: 'game-show-more-background', value: '#42abdf' },
    { name: 'game-show-more-color', value: 'white' },
    { name: 'gameTileFeatured', value: '#371b43' },
    { name: 'gameTileHighestWin', value: '#009f66' },
    { name: 'gameTileNewest', value: '#1fb6be' },
    { name: 'gameTileNewestProvider', value: '#fdac00' },
    { name: 'gameTileNewestTable', value: '#3c3abb' },
    { name: 'gameTilePopular', value: '#fa5847' },
    { name: 'conditionalGameTileText', value: '#ffffff' },
    { name: 'htmlContent', value: '#43484F' },
    { name: 'info', value: '#1fb6be' },
    { name: 'input', value: '#c9c9c9' },
    { name: 'inputBackground', value: '#ffffff' },
    { name: 'inputDisabled', value: '#e8e7e8' },
    { name: 'inputHover', value: '#a9a9a9' },
    { name: 'inputText', value: '#43484F' },
    { name: 'inputBorder', value: '#C2C2C2' },
    { name: 'kewlLinkIconBackground', value: '#ffffff' },
    { name: 'kewlLinkIconColor', value: '#17061d' },
    { name: 'scrollBarThumb', value: '#0D989F' },
    { name: 'scrollBarTrack', value: '#371b43' },
    { name: 'spinner-color', value: '#42abdf' },
    { name: 'secondary', value: '#41a9de' },
    { name: 'secondaryButton', value: '#ffffff' },
    { name: 'secondaryActive', value: '#0D989F' },
    { name: 'secondaryHover', value: '#28cad3' },
    { name: 'provider-border-selected', value: 'hsl(208.74deg 64.32% 36.27%)' },
    { name: 'provider-border', value: 'white' },
    { name: 'provider-border-hover', value: 'hsl(199.87deg 71.04% 56.67%)' },
    { name: 'recovery-text', value: 'black' },
    { name: 'selectText', value: '#43484F' },
    { name: 'success', value: '#009f66' },
    { name: 'textDanger', value: '#fa5847' },
    { name: 'warning', value: '#FF3D71' },
    { name: 'text', value: '#666975' },
    { name: 'base-gray', value: '#ACACAC' },
    { name: 'search-provider-button', value: '#c2c2c2' },
    { name: 'provider-button-selected', value: '#4abaeb' },
    { name: 'blueberry', value: '#26125E' },
    { name: 'lavender', value: '#8CACFF' },
    { name: 'dark-purple', value: '#1A0B41' },
  ],

  fonts: [
    {
      name: 'head',
      value: 'Poppins, Arial, sans-serif',
    },
    {
      name: 'body',
      value: 'Poppins, Arial, sans-serif',
    },
    {
      name: 'proxima',
      value: 'Poppins, Arial, sans-serif',
    },
  ],

  fontSizes: [
    { value: 12 },
    { value: 13 },
    { value: 14 },
    { value: 15 },
    { value: 16 },
    { value: 18 },
    { value: 22 },
    { value: 26 },
  ],

  radii: [{ value: 4 }, { value: 8 }],

  space: [
    { value: 8 },
    { value: 16 },
    { value: 24 },
    { value: 32 },
    { value: 40 },
    { value: 64 },
    { value: 80 },
    { value: 112 },
    { value: 160 },
  ],

  zIndices: [
    { name: 'hide', value: -1 },
    { name: 'base', value: 0 },
    { name: 'docked', value: 1 },
    { name: 'small', value: 10 },
    { name: 'medium', value: 100 },
    { name: 'high', value: 110 },
    { name: 'highest', value: 999 },
  ],

  shadows: [
    { value: '0 3px 4px 0 rgba(0, 0, 0, 0.1)' },
    { value: '0 7px 14px 0 rgba(0, 0, 0, 0.1)' },
    { value: '0 7px 40px 0 rgba(0, 0, 0, 0.1)' },
  ],

  variants: [
    {
      name: 'buttons',
      value: {
        primary: {
          transition: '.4s',
          borderRadius: '9999px',
          color: 'button-text',
          background:
            'linear-gradient(to right, #18D1B1, #8335FF, #8335FF,#18D1B1)',
          backgroundSize: '300% 100%',
          border: 'none',
          '&:disabled': {
            opacity: 0.3,
            cursor: 'not-allowed',
          },
          '&:hover:enabled, &:focus': {
            backgroundPosition: '100% 0',
          },
        },
        secondary: {
          transition: '.3s',
          borderRadius: '9999px',
          color: 'button-text',
          border: '2px solid',
          borderColor: 'secondaryButton',
          bg: 'transparent',
          '@media (min-width: 568px)': {
            border: '1px solid',
          },
          '&:hover:enabled, &:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
          },
          '&:active:enabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.20)',
          },
          '&:disabled': {
            opacity: 0.3,
            cursor: 'not-allowed',
          },
        },
      },
    },
  ],
})
