import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Http from '../http-module'
import { redirect } from '../http'
import { useServerConfiguration } from '../server-configuration'

export function useLocaleRedirect() {
  const configuration = useServerConfiguration()
  const dispatch = ReactRedux.useDispatch()
  const basename = Http.useBasename()
  const url = Http.useUrl()
  // TODO: Migrate to `http-module`
  const redirected = React.useMemo(() => {
    const nextBasename = `/${configuration?.locale?.slug}`
    const isLocaleIncorrect = basename !== nextBasename

    if (isLocaleIncorrect) {
      dispatch(redirect(`${nextBasename}${url}`))

      if (process.browser) {
        window.location.replace(`${nextBasename}${url}`)
      }

      return true
    }

    return false
  }, [basename, configuration, dispatch, url])

  return redirected
}
