import PropTypes from 'prop-types'
import { typography, variant } from 'styled-system'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

export const Button = styled('button', {
  shouldForwardProp: Common.noneOf([
    'as',
    'stretch',
    'fontSize',
    'fontFamily',
    'variant',
    'small',
  ]),
})`
  cursor: pointer;
  outline: none;

  ${props => (props.small ? `padding: 8px 5px` : 'padding: 12px 16px')};

  font-weight: bold;
  line-height: 1.15;
  text-transform: uppercase;
  margin: 0;
  ${props => props.stretch && `width: 100%`};
  // Workaround: theme 'variants' are not read otherwise
  ${variant({
    scale: 'buttons',
    variants: { _: {} },
  })};
  ${typography};
`

Button.displayName = 'Button'

Button.defaultProps = {
  fontSize: 2,
  variant: 'secondary',
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  stretch: PropTypes.bool,
  onClick: PropTypes.func,
}
